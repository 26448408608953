import React from "react";

const over_content = <p>
    Mocht jij ook een schrijver zijn, die graag zijn/haar boek wil publiceren dan kunnen wij daarbij helpen. De kosten
    van drukken (of voor het uitgeven als ebook) zijn voor jou, want als kleine uitgeverij kunnen we die niet
    dragen. Wel kunnen we een ISBN-nummer aanvragen, jou in contact brengen met organisaties voor lay-out,
    epub formaat of het drukken van het boek. Ook kunnen we jou in contact brengen met een illustrator. <br/><br/>

    Ten slotte kunnen we ervoor zorgen dat jouw boek via Sentia in het centraal boekhuis ligt. Hierdoor is deze te
    bestellen via bol.com, managementboek.nl en alle fysieke boekhandels in Nederland en België. Het Centraal Boekhuis
    verzorgt de distributie en via de Vrije Uitgevers, waarbij wij als Sentia zijn aangesloten, wordt de betaling
    verzorgd. <br/><br/>

    Mocht je hiervoor belangstelling hebben, neem dan rustig contact op.
</p>


export default over_content;
import React from "react";
import './Header.css';
import fly from '../../assets/images/sentia-vlieg.jpg'

const Header = (props) => {
    return (
        <div style={{backgroundColor: props.backgroundColor}}
             className='header'>
            <div className={'header-text'}>ACADEMIC PUBLISHERS SENTIA</div>
            <div className="header-fly-box"><img className="header-fly" src={fly} alt="Logo" /></div>
        </div>
    )
};

export default Header;
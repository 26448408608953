import React from "react";
import Base from '../Base';

import contact_content from "./content.js";
import './Contact.css';

import fly from "../../assets/images/sentia-vlieg.jpg";

const Contact = (props) => {
    const email = contact_content['email_address'].join("@");
    return (
        <Base headerColor='#00D4D3' app={props.app}>
            <div className="contact-container">
                <div className='contact-container-column'>
                    <div>
                        {contact_content['address_lines'].map(
                            (line, index) =>
                                <div key={index}>
                                    {line}
                                </div>
                        )
                        }
                    </div>
                    <br/>
                    <div className={'contact-email'}>
                        <a href={"mailto:" + email}>{email}</a>
                    </div>
                    <div>
                        <img className='contact-fly'
                             src={fly} alt="logo-fly"/>
                     </div>

                </div>
            </div>
        </Base>
    )
};

export default Contact;

import React from "react";
import Base from '../Base';

import home_content from "./content.js";
import fly from "../../assets/images/sentia-vlieg.jpg";

const Home = (props) => {
    return (
        <Base headerColor='#00D4D3' app={props.app}>
            <div>
                <div>
                    {home_content['upper']}
                </div>
                <div className="home-fly-big-container">
                <img className='home-fly-big'
                     src={fly} alt="logo-fly"/>
                </div>
                <div>
                    {home_content['lower']}
                </div>

            </div>
        </Base>
    )
};

export default Home;

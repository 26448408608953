import React from "react";
import Home from '../Home';
import Boeken from '../Boeken';
import Nieuws from '../Nieuws';
import Over from '../Over';
import Contact from '../Contact';

// Update key to allow a reset on books.
let book_key = 0;

class App extends React.Component {

    state = {
        current_page: 'home'
    };

    render_switch(current_page) {
        switch(current_page) {
            case "home":
                return <Home app={this}/>;
            case "boeken":
                return <Boeken app={this} key={book_key++}/>;
            case "nieuws":
                return <Nieuws app={this}/>
            case "over":
                return <Over app={this}/>
            case "contact":
                return <Contact app={this}/>
            default:
                return <Home app={this}/>;
        }
    }

    render() {
        return (
                <div>{this.render_switch(this.state.current_page)}</div>
        );
    }
}

export default App;

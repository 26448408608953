const contact_content = {
    address_lines: [
        "Sentia Academic Publishers",
        "Nemelaer 1",
        "4901 CX Oosterhout"
    ],
    email_address: ["uitgeverij.sentia","gmail.com"]
}

export default contact_content;

import React from "react";

const nieuws_content = <p>
    Binnenkort brengen we het boek Actieonderzoek uit. Dit is een onderdeel van de reeks over Kwalitatief Onderzoek,
    waarvan ons boek Kwalitatief Onderzoeken een eerste deel is.<br/><br/>
    In het boek Actieonderzoek geven we in begrijpelijke taal, met veel voorbeelden en een duidelijke beschrijving van
    de stappen in dit onderzoek voor zowel docenten als studenten, als ook de onderzoekende professionals die hun
    eigen praktijk willen verbeteren. <br/><br/>
    Een belangrijke vraag van (beginnende) actieonderzoekers is welke actie ze zouden
    kunnen inzetten om de verandering de goede kant op te krijgen/leiden.
    In dit boek leren we niet alleen
    onderzoeksvaardigheden op het gebied van actieonderzoek, maar ook veranderkundige vaardigheden; welke acties in
    welke situa- ties kunnen werken
</p>


export default nieuws_content;
import React from "react";
import Base from '../Base';

import nieuws_content from "./content.js";

const Nieuws = (props) => {
    return (
        <Base headerColor='#00D4D3' title={"VERWACHTE BOEKEN"} app={props.app}>
            <div>
                {nieuws_content}
            </div>
        </Base>
    )
};

export default Nieuws;

import React from "react";
import Base from '../Base';

import over_content from "./content.js";

const Over = (props) => {
    return (
        <Base headerColor='#00D4D3' title="UITGEVEN BIJ SENTIA" app={props.app}>
            <div>
                {over_content}
            </div>
        </Base>
    )
};

export default Over;

import React from "react";
import Base from '../Base';
import boeken_content from "./content.js";
import "./Boeken.css";


const boeken_overview_color = '#A1C8E1';


const BookSplitBar = () => {
    return (
        <div className='book-split-bar' style={{
            borderColor: boeken_overview_color
        }}/>
    )
};


const BookShort = (props) => {
    return (
        <div className="book-wrapper">
            <img className="book-cover-image" src={props.book.cover} alt="Logo"/>
            <div className="book-text book-text-short">
                {props.book.text_short}
                <br/>
                <br/>
                <a href={'./#'} onClick={props.readMoreCallback}>Lees meer</a>
            </div>
        </div>

    )
};

const BookLong = (props) => {
    return (
        <div className='book-long'>
            <p className='book-title'>{props.book.title}</p>
            <img className="book-cover-image-long" src={props.book.cover} alt="Logo"/>
            <div className='book-text'>
                {props.book.text_long}
            </div>
            <br/>
            <br/>
            <div className={"book-text-long"}>
                <a href={'./#'} onClick={props.overviewCallback}>Terug naar overzicht</a>
            </div>
        </div>
    )
}


class Boeken extends React.Component {

    local_state = {overview_scroll_position: 0};

    constructor(props) {
        super(props);
        this.state = {
            current_book: 0,
            overview: true,
        }
    }

    read_more_callback_base(book_index) {
        return () => {
            this.setState({
                overview: false,
                current_book: book_index
            })
        }
    }

    overview_callback = () => {
        this.setState({overview: true})
    }


    render_book_short = (book, index) => {
        const read_more_callback = this.read_more_callback_base(index)

        return (
            <div key={index}>
                {(index > 0) && <BookSplitBar/>}
                <BookShort book={book} readMoreCallback={read_more_callback}/>
            </div>
        );
    }

    render_book_long = (book) => {
        return <BookLong book={book} overviewCallback={this.overview_callback}/>
    }

    // This is probably bad form since it breaks the pure component which now depends on Base
    // Then again this was already the case since it imports and calls Base.
    save_overview_scroll_position = () => {
        this.local_state['overview_scroll_position'] = document.getElementById("base-text-box").scrollTop;
    }

    set_scroll_position = () => {
        document.getElementById("base-text-box").scrollTop = this.state.overview ?
            this.local_state['overview_scroll_position'] : 0;
    }

    componentDidMount() {
        console.log("Mounting");
    }

    render() {
        const header_color = this.state.overview ?
            boeken_overview_color :
            boeken_content[this.state.current_book]['header_color']

        // Handle scroll position
        if (!this.state.overview) {
            this.save_overview_scroll_position()
        }
        this.set_scroll_position()

        return (
            <Base headerColor={header_color} app={this.props.app}>
                {this.state.overview ? boeken_content.map(this.render_book_short) :
                 this.render_book_long(boeken_content[this.state.current_book])}
            </Base>
        )
    }
}

export default Boeken;

import React from "react";

import './Home.css';

const home_content = {
    upper: <p>Wij zijn een uitgeverij van boeken, waarin
        <span className={'home-text-emphasis'}> actuele academische en populair-wetenschappelijke onderwerpen </span>
        zo toegankelijk mogelijk worden besproken.</p>,
    lower: <p>Onze boeken zijn te bestellen via de digitale boekhandels zoals{" "}
        <a href="https://www.bol.com"
           target="_blank"
           rel="noopener noreferrer">bol.com</a>
        {" "}en{" "}
        <a href="https://www.managementboek.nl"
           target="_blank"
           rel="noopener noreferrer"> managementboek.nl</a>,
        maar ook via alle fysieke boekhandels.</p>
}

export default home_content;
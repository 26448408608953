import React from "react"
import col_int_cover from "../../assets/images/cover_collect_int.png";
import kwa_ond_cover from "../../assets/images/cover_kwal_onder.png";


const boeken_content = [
    {
        "title": "COLLECTIEVE INTELLIGENTIE",
        "cover": col_int_cover,
        "header_color": '#63A8DB',
        "text_short": "Collectieve intelligentie is het vermogen van een groep organismen (of machines) om problemen op te lossen. Het is mogelijk voor organisaties om collectieve intelligentie te bevorderen op basis van simpele gedragsregels. Hierdoor is er veel te winnen zonder grote investeringen te doen.",
        "text_long": <p>
            Collectieve intelligentie is het vermogen van een een groep mensen (of dieren, planten, cellen,
            micro-organismen en/of machines) om problemen op te lossen. In de natuur zijn veel voorbeelden hiervan te
            vinden, bij insectenkolonieën en zwermen. <br/>
            De mogelijkheden om collectieve intelligentie op basis van zelforganisatie en simpele gedragsregels te
            organiseren en te bevorderen, zijn bijzonder interessant voor organisaties. Door deze principes toe te
            passen in de organisatie, is er al veel te winnen zonder grote investeringen te hoeven doen.<br/><br/>
            De ontwikkeling van collectieve intelligentie in de natuur heeft grote gevolgen voor de mensheid. Het feit
            dat mensen complexe breinen hebben, leidt tot de ontwikkeling van steeds intelligentere groepen.
            Technologieën en met name het Internet van Intelligente Dingen spelen een steeds belangrijkere rol in het
            zichzelf ontwikkelende en zelforganiserende 'wereldbrein'. <br/>
            Al deze ontwikkelingen hebben grote invloed op organisaties. Managementtaken verschuiven naar de zorg voor
            infrastructuren voor collectieve intelligentie, in de vorm van open culturen, uitdagende doelen, sociale
            netwerken, gemeenschappen en open markten.<br/><br/>
            Dit boek gaat niet alleen over organisaties; het gaat ook over de digitale transformatie van individuele
            mensen.<br/>
            In dit boek wordt op een toegankelijke manier, met veel voorbeelden uit de natuur en 'mensen'-organisaties,
            aangegeven wat er aan de hand is en welke gevolgen collectieve intelligentie heeft voor ons als mens en als
            werknemer/manager
        </p>
    },
    {
        "title": "KWALITATIEF ONDERZOEKEN",
        "cover": kwa_ond_cover,
        "header_color": '#00D4D3',
        "text_short": "Op een duidelijke en toegankelijke manier worden in dit boek belangrijke kwalitatieve onderzoeksbenaderingen beschreven. Deze helpen bij het opzetten, uitvoeren, analyseren en rapporteren van een kwalitatief onderzoek. In dit boek staat de relatie tussen praktijk en theorie centraal, waarbij beide gelijkwaardig zijn.",
        "text_long": <p>
            In dit boek worden op een duidelijke en toegankelijke manier kwalitatieve onderzoeksbenaderingen beschreven,
            die kunnen helpen bij het opzetten, uitvoeren, analyseren en rapporteren van een kwalitatief onderzoek. <br/><br/>
            In dit boek staat de relatie tussen praktijk en theorie centraal, waarbij beide gelijkwaardig zijn. De lezer
            wordt daarom aangesproken als onderzoekende professional. Professionals hebben te maken met venijnige
            problemen. Bij venijnige problemen zullen er geen oplossingen zijn die in veel situaties werken - anders
            waren ze al gevonden. <br/>
            In plaats van snelle antwoorden te zoeken, gaat het om het kunnen stellen van trage vragen. Een
            onderzoekende houding helpt bij het kiezen van de essentiële kenmerken van de situatie en het creëren van
            samenhang. Zo kan de professional ervoor zorgen dat het oordelen over goede oplossingen een plaats krijgt.
            Dit is de reden waarom in toenemende mate aandacht is voor verhalen en narratief onderzoek bij het
            bestuderen van organisaties: men begint steeds meer in te zien dat verhalen een belangrijke rol spelen in
            organisaties. <br/><br/>
            Dit boek is bedoeld voor gevorderde studenten van HBO, universiteit én cursisten van Professionele Masters.
            Dit boek biedt ook een kennismaking met kwalitatieve onderzoeksmethoden voor professionals die
            geïnteresseerd zijn in manieren om hun eigen praktijk te onderzoeken en vorm te geven. Eigenlijk zou bij
            iedere zichzelf respecterende professional (in spe) dit boek in zijn of haar boekenkast moeten staan.

        </p>
    }
];

export default boeken_content;
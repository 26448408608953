import React from "react";
import Header from './Header';
import NavBar from './NavBar';
import './Base.css';

const Base = (props) => {
    return (
        <div style={{width: '100%'}}>
            <Header backgroundColor={props.headerColor}/>
            <div className={'app-main-container'}>
                <div className={'base-container'}>
                    <div style={{height: 550, width: '100%'}}>
                        <NavBar app={props.app}/>
                        <div id={"base-text-box"}
                             className={"base-text-box"}
                             style={{
                                 borderColor: props.headerColor,
                             }}>
                            {props.title ? <div className={'base-title'}>{props.title}</div> : null}
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default Base;
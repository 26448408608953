import React from "react";
import "./NavBar.css";

const NavBarToggle = (props) => {
    return <svg onClick={props.onClick}
                aria-labelledby="title" viewBox="0 0 30 30" height="30px" width="30px">
        <title id="title">Toggle Icon</title>
        <path className="toggler-svg" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" stroke='black'
              d="M4 7h22M4 15h22M4 23h22"/>
    </svg>
}


class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nav_toggle: false
        }
    }

    links = ["home", "boeken", "nieuws", "over", "contact"];

    change_page = (page_name) => {
        this.props.app.setState({current_page: page_name});
    };

    render_link = (link, index) => {
        return <a key={index}
                  href="/#"
                  className={this.props.app.state.current_page === link ? 'nav-link' : 'nav-link-active'}
                  onClick={() => {
                      this.change_page(link)
                  }}>{link.toUpperCase()}</a>;
    };

    toggle_nav = () => {
        this.setState({nav_toggle: !this.state.nav_toggle})
    }

    render_small_link = (link, index) => {
        // return this.render_link(link, index);
        return (<div key={index} className={'navbar-small-link'}>
            <a
                href="/#"
                className={this.props.app.state.current_page === link ? 'nav-link' : 'nav-link-active'}
                onClick={() => {
                    this.change_page(link)
                }}>{link.toUpperCase()}</a>
        </div>);
    }

    render() {
        return (

            <div className='navbar'>
                <div className={'navbar-big'}>
                    {this.links.map(this.render_link)}
                </div>
                <div className={'navbar-small'}>
                    <div className={'navbar-toggle-container'}>
                        <NavBarToggle onClick={this.toggle_nav}/>
                    </div>
                    <div className={'navbar-small-links'}>
                        {this.state.nav_toggle ? this.links.map(this.render_small_link) : null}
                    </div>
                </div>
            </div>

        )
    }
}

export default NavBar;